
export const serverAppConfig = {
  "storeId": "66f29a0cb11f02729eb02f2d",
  "analytics": null,
  "privacy": null,
  "storeType": "MARKETPLACE",
  "storeDeliveries": [],
  "language": "sv",
  "currency": "SEK",
  "labels": {
    "currency": "SEK",
    "priceCurrency": "SEK"
  },
  "paths": {
    "brandsUrlPath": "/brands",
    "categoriesUrlPath": "/categories",
    "tagsUrlPath": "/tags",
    "productsUrlPath": "/products",
    "blogsUrlPath": "/blog"
  },
  "staticLinks": [
    {
      "id": "brands",
      "label": "Brands",
      "path": "/brands"
    },
    {
      "id": "blog",
      "label": "Blog",
      "path": "/blog"
    }
  ],
  "logoUrl": "https://storage.googleapis.com/gecko-media/EC_1068/logo/tradlr_logo_1.png",
  "showCart": false,
  "sortingOptions": [
    {
      "sortBy": "price",
      "sortOrder": "DESC",
      "sortLabel": "Price high-low",
      "default": false
    },
    {
      "sortBy": "price",
      "sortOrder": "ASC",
      "sortLabel": "Price low-high",
      "default": true
    },
    {
      "sortBy": "created",
      "sortOrder": "DESC",
      "sortLabel": "Newest",
      "default": false
    }
  ],
  "desktopItemHeight": "20vw",
  "mobileItemHeight": "45vw",
  "sliderDefaultValues": [
    0,
    200000
  ],
  "freeShippingOver": 0,
  "shippingProviderConfig": {
    "provider": "DEFAULT",
    "simpleShipping": {}
  },
  "paymentProviderConfig": {
    "providerName": "KLARNA",
    "providerOptions": {
      "klarna": {
        "purchaseCurrency": "SEK"
      }
    }
  },
  "filtersBeforeShowMore": 3,
  "sliderMaxPrice": 200000,
  "topNavHeight": {
    "panel": {
      "base": 0,
      "sm": "5vh"
    },
    "content": {
      "base": "8vh",
      "sm": "14vh"
    },
    "spacer": {
      "base": "8vh",
      "sm": "19vh"
    }
  },
  "topNavSlideOutFeaturedCategories": 3,
  "socialMediaItems": [
    {
      "label": "Facebook",
      "icon": "FACEBOOK",
      "href": "https://www.facebook.com/granditude/?locale=sv_SE"
    },
    {
      "label": "Instagram",
      "icon": "INSTAGRAM",
      "href": "https://www.instagram.com/granditude/?hl=en"
    }
  ],
  "topNavPanel": {
    "items": []
  }
};
export const serverTheme = {}; 
